const ro_RO = {}

ro_RO.strings = {
  addBulkFilesFailed: {
    '0': 'Nu s-a adÃÂugat %{smart_count} fiÃÂier datoritÃÂ unei erori interne',
    '1': 'Nu s-au adÃÂugat %{smart_count} fiÃÂiere datoritÃÂ unei erori interne',
  },
  addMore: 'AdaugÃÂ mai multe',
  addMoreFiles: 'AdaugÃÂ mai multe fiÃÂiere',
  addingMoreFiles: 'Se adaugÃÂ mai multe fiÃÂiere',
  allowAccessDescription: 'Pentru a face poze sau ÃÂ®nregistra video trebuie sÃÂ permiÃÂi accesul la camerÃÂ.',
  allowAccessTitle: 'VÃÂ rugÃÂm permiteÃÂi accesul la camerÃÂ',
  authenticateWith: 'ConectaÃÂi-vÃÂ cu %{pluginName}',
  authenticateWithTitle: 'VÃÂ rugÃÂm conectaÃÂi-vÃÂ cu %{pluginName} pentru a selecta fiÃÂiere',
  back: 'ÃÂnapoi',
  browse: 'rasfoieÃÂte',
  browseFiles: 'rasfoieÃÂte',
  cancel: 'Anulare',
  cancelUpload: 'AnuleazÃÂ ÃÂ®ncÃÂrcarea',
  chooseFiles: 'SelecteazÃÂ fiÃÂiere',
  closeModal: 'ÃÂnchide fereastra',
  companionError: 'Conexiunea cÃÂtre Companion nu a reuÃÂi',
  companionUnauthorizeHint: 'Pentru a autoriza contul %{provider}, navigaÃÂi cÃÂtre %{url}',
  complete: 'Complet',
  connectedToInternet: 'Conectat la Internet',
  copyLink: 'CopiazÃÂ link',
  copyLinkToClipboardFallback: 'CopiazÃÂ URL-ul de mai jos',
  copyLinkToClipboardSuccess: 'Link-ul copiat ÃÂ®n clipboard',
  creatingAssembly: 'Se pregÃÂteÃÂte ÃÂ®ncÃÂrcarea...',
  creatingAssemblyFailed: 'Transloadit: Nu se poate crea un Assembly',
  dashboardTitle: 'ÃÂncÃÂrcare fiÃÂier',
  dashboardWindowTitle: 'FereastrÃÂ ÃÂ®ncÃÂrcare fiÃÂier (ApasÃÂ tasta escape pentru a ÃÂ®nchide)',
  dataUploadedOfTotal: '%{complete} din %{total}',
  done: 'Finalizat',
  dropHereOr: 'Trage fiÃÂierele aici sau %{browse}',
  dropHint: 'Trage fiÃÂierele tale aici',
  dropPasteBoth: 'Trage fiÃÂierele aici, copy/paste sau %{browse}',
  dropPasteFiles: 'Trage fiÃÂierele aici, copy/paste sau %{browse}',
  dropPasteFolders: 'Trage fiÃÂierele aici, copy/paste sau %{browse}',
  dropPasteImportBoth: 'Trage fiÃÂierele aici, copy/paste, %{browse} sau importÃÂ din:',
  dropPasteImportFiles: 'Trage fiÃÂierele aici, copy/paste, %{browse} sau importÃÂ din:',
  dropPasteImportFolders: 'Trage fiÃÂierele aici, copy/paste, %{browse} sau importÃÂ din:',
  editFile: 'EditeazÃÂ fiÃÂier',
  editing: 'Se editeazÃÂ %{file}',
  emptyFolderAdded: 'Nu s-au adÃÂugat fiÃÂiere, directorul este gol',
  encoding: 'Encodare...',
  enterCorrectUrl: 'URL incorect: IntroduceÃÂi un link direct cÃÂtre fiÃÂier',
  enterUrlToImport: 'IntroduceÃÂi URL pentru a importa fiÃÂierul',
  exceedsSize: 'FiÃÂierul depÃÂÃÂeÃÂte dimensiunea maximÃÂ permisÃÂ de %{size}',
  failedToFetch: 'Companion-ul nu a putut procesa URL-ul, asigurÃÂ-te cÃÂ e corect introdus',
  failedToUpload: '%{file} nu a putut fi ÃÂ®ncÃÂrcat',
  fileSource: 'SursÃÂ fiÃÂier: %{name}',
  filesUploadedOfTotal: {
    '0': 'FiÃÂier importat %{complete} din %{smart_count}',
    '1': 'FiÃÂiere importate %{complete} din %{smart_count}',
  },
  filter: 'FiltreazÃÂ',
  finishEditingFile: 'FinalizeazÃÂ editarea fiÃÂierului',
  folderAdded: {
    '0': 'S-a adÃÂugat %{smart_count} fiÃÂier din %{folder}',
    '1': 'S-au adÃÂugat %{smart_count} fiÃÂiere din %{folder}',
  },
  generatingThumbnails: 'Se genereazÃÂ pictogramele...',
  import: 'ImportÃÂ',
  importFrom: 'ImportÃÂ din %{name}',
  loading: 'ÃÂncÃÂrcare...',
  logOut: 'Delogare',
  myDevice: 'Dispozitivul meu',
  noDuplicates: 'Nu se poate adÃÂuga fiÃÂierul \'%{fileName}\', acesta existÃÂ deja',
  noFilesFound: 'Nu sunt fiÃÂiere sau directoare aici',
  noInternetConnection: 'FÃÂrÃÂ conexiune la internet',
  noMoreFilesAllowed: 'Nu se pot adÃÂuga fiÃÂiere noi: ÃÂ®ncÃÂrcare ÃÂ®n curs',
  openFolderNamed: 'Deschide director %{name}',
  pause: 'ÃÂntrerupe',
  pauseUpload: 'ÃÂntrerupe ÃÂ®ncÃÂrcarea',
  paused: 'ÃÂntrerupt',
  poweredBy: 'SusÃÂinut de %{uppy}',
  processingXFiles: {
    '0': 'Se proceseazÃÂ %{smart_count} fiÃÂier',
    '1': 'Se proceseazÃÂ %{smart_count} fiÃÂiere',
  },
  recordingLength: 'DuratÃÂ ÃÂ®nregistrare %{recording_length}',
  recordingStoppedMaxSize: 'ÃÂnregistrarea a fost opritÃÂ pentru cÃÂ a depÃÂÃÂit dimensiunea maximÃÂ permisÃÂ',
  removeFile: 'EliminÃÂ fiÃÂier',
  resetFilter: 'ReseteazÃÂ filtre',
  resume: 'Reia',
  resumeUpload: 'Reia ÃÂ®ncÃÂrcarea',
  retry: 'ReÃÂ®ncercare',
  retryUpload: 'ReÃÂ®ncearcÃÂ ÃÂ®ncÃÂrcarea',
  saveChanges: 'SalveazÃÂ modificÃÂri',
  selectFileNamed: 'SelecteazÃÂ fiÃÂier %{name}',
  selectX: {
    '0': 'SelecteazÃÂ %{smart_count}',
    '1': 'SelecteazÃÂ %{smart_count}',
  },
  smile: 'ZÃÂ¢mbeÃÂte!',
  startRecording: 'PorneÃÂte ÃÂ®nregistrarea video',
  stopRecording: 'OpreÃÂte ÃÂ®nregistrarea video',
  takePicture: 'FÃÂ o pozÃÂ',
  timedOut: 'ÃÂncÃÂrcarea blocatÃÂ pentru %{seconds} secunde, se anuleazÃÂ.',
  unselectFileNamed: 'DeselecteazÃÂ fiÃÂier %{name}',
  upload: 'ÃÂncarcÃÂ',
  uploadComplete: 'ÃÂncÃÂrcare finalizatÃÂ',
  uploadFailed: 'Probleme la ÃÂ®ncÃÂrcare',
  uploadPaused: 'ÃÂncÃÂrcare ÃÂ®ntreruptÃÂ',
  uploadXFiles: {
    '0': 'ÃÂncarcÃÂ %{smart_count} fiÃÂier',
    '1': 'ÃÂncarcÃÂ %{smart_count} fiÃÂiere',
  },
  uploadXNewFiles: {
    '0': 'ÃÂncarcÃÂ +%{smart_count} fiÃÂier',
    '1': 'ÃÂncarcÃÂ +%{smart_count} fiÃÂiere',
  },
  uploading: 'ÃÂncÃÂrcare',
  uploadingXFiles: {
    '0': 'Se ÃÂ®ncarcÃÂ %{smart_count} fiÃÂier',
    '1': 'Se ÃÂ®ncarcÃÂ %{smart_count} fiÃÂiere',
  },
  xFilesSelected: {
    '0': '%{smart_count} fiÃÂier selectat',
    '1': '%{smart_count} fiÃÂiere selectate',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} fiÃÂier adÃÂugat',
    '1': '%{smart_count} fiÃÂiere adÃÂugate',
  },
  xTimeLeft: '%{time} rÃÂmas(e)',
  youCanOnlyUploadFileTypes: 'PoÃÂi ÃÂ®ncÃÂrca doar: %{types}',
  youCanOnlyUploadX: {
    '0': 'PoÃÂi ÃÂ®ncÃÂrca doar %{smart_count} fiÃÂier',
    '1': 'PoÃÂi ÃÂ®ncÃÂrca doar %{smart_count} fiÃÂiere',
  },
  youHaveToAtLeastSelectX: {
    '0': 'SelecteazÃÂ cel puÃÂin %{smart_count} fiÃÂier',
    '1': 'SelecteazÃÂ cel puÃÂin %{smart_count} fiÃÂiere',
  },
}

ro_RO.pluralize = function pluralize (count) {
  if (count === 1) {
    return 0
  }
  return 1
}

if (typeof window !== 'undefined' && typeof window.Uppy !== 'undefined') {
  window.Uppy.locales.ro_RO = ro_RO
}

module.exports = ro_RO
